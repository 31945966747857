import React from 'react'

import banner from "../../images/schindler-header.png"
import flyerDE from "../../images/schindler-logo-de.png"
import flyerFR from "../../images/schindler-logo-fr.png"
import flyerIT from "../../images/schindler-logo-it.png"

const Header = (props) => {

    const { language } = props;

    return (
        <div className='mb-5'>
            <div className='row'>
                <div className='col-12 fill mb-4'>
                    <img className='img-fluid' src={banner} alt="schindler banner"></img>
                </div>
                <div className='col-12 fill'>
                    <img className='text-center flyer img-fluid' src={
                        language == "de" ? flyerDE :
                            language == "fr" ? flyerFR :
                                language == "it" ? flyerIT :
                                    flyerDE} alt="schindler logo"></img>
                </div>
            </div>
        </div>
    )
}

export default Header