import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "ticketino-api-client";
import parse from "html-react-parser";

import Header from './components/Header'
import Footer from './components/Footer'

const Confirmation = () => {
    const [token] = useState(sessionStorage.getItem("token"));
    const [order, setOrder] = useState({});
    const [organizerId, setOrganizerId] = useState(0);

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    // setting the base url of the npm package api calls
    api.defaults.baseURL = baseUrl;

    // fetching resources
    const [resources, setResources] = useState({});

    // fetching resources
    const { language, orderId } = useParams();

    let languageId = 0;

    // changing languageId according to the url
    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("fr" || "FR"):
            languageId = 2;
            break;
        case ("en" || "EN"):
            languageId = 3;
            break;
        case ("it" || "IT"):
            languageId = 4;
            break;
        default:
            languageId = 0;
            break;
    }

    useEffect(() => {
        loadToken();
    }, []); //only on first page load

    useEffect(() => {
        requestResources();
    }, [language]); //everytime language is changed

    const loadToken = async () => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        api.defaults.headers.common['Authorization'] = "Bearer " + token;

        requestFormSettings()
    };

    const requestFormSettings = async () => {
        await axios.get(`form/formsettings`).then((res) => {
            setOrganizerId(res.data.organizerId);
            loadOrder(orderId);
        });
    };

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data.translation);
            })
            .catch((error) => console.error(error.response.data));
    };

    const loadOrder = async (orderId) => {
        try {
            // Order
            const updatedOrder = await getOrderByOrderId(orderId);
            setOrder(updatedOrder);
        }
        catch (error) {
            console.error(error);
        }
    }

    const getOrderByOrderId = async (orderId) => {
        try {
            const res = await axios.get(`${baseUrl}/Order/${orderId}`);
            return res.data;
        }
        catch (error) {
            console.error(error);
        }
    }

    const getPdfTicketsOfOrder = async () => {
        await axios
            .get(
                `${baseUrl}/Order/${orderId}/TicketsPdf?organizerId=${organizerId}`,
                {
                    responseType: "blob",
                }
            )
            .then((res) => {
                const data = window.URL.createObjectURL(res.data);
                var link = document.createElement("a");
                link.href = data;
                link.download = "Tickets.pdf";
                link.click();
            })
            .catch((error) => console.error(error.response.data));
    };

    return (
        <div className='container page-container'>
            <Header language={language} />
            <div className='text-center'>
                <p className='fs-5 mb-5'>{parse(resources?.ConfirmationText ?? "")}</p>
                <div className='center'>
                    <button className='button btn mb-4' onClick={getPdfTicketsOfOrder}>{resources?.ConfirmationButton}</button>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Confirmation