import React from 'react'

import footer from "../../images/schindler-footer.png"

const Footer = () => {
    return (
        <div className='footer mt-5'>
            <div className='row mb-0'>
                <div className='col-12 fill'>
                    <img className='img-fluid' src={footer} alt="schindler footer"></img>
                </div>
            </div>
        </div>
    )
}

export default Footer