import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom/dist';
import axios from 'axios';
import { api } from 'ticketino-api-client';
import parse from "html-react-parser";

import Header from './components/Header'
import Footer from './components/Footer'

const Event = () => {
    const { eventId, language } = useParams();
    const [loading, setLoading] = useState(true);
    const [selectedEvent, setSelectedEvent] = useState(0);

    let languageId = 0;

    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("fr" || "FR"):
            languageId = 2;
            break;
        case ("en" || "EN"):
            languageId = 3;
            break;
        case ("it" || "IT"):
            languageId = 4;
            break;
        default:
            languageId = 0;
            break;
    }

    let navigate = useNavigate();

    let baseUrl = process.env.REACT_APP_BASEURL_API;
    api.defaults.baseURL = baseUrl;

    const [resources, setResources] = useState({});

    useEffect(() => {
        requestResources();
    }, [language]); //everytime language is changed

    useEffect(() => {
        requestFormSettings();
    }, [])

    const requestFormSettings = async () => {
        await axios.get(`form/formsettings`).then((res) => {
            setSelectedEvent(res.data.eventId1);
        });
    };

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data?.translation);
                setLoading(false);
            })
            .catch((error) => console.error(error.response.data));
    };

    const onSubmit = () => {
        navigate(`/${language}/login/${selectedEvent}`)
    }

    return (
        <div className='container page-container'>
            <Header language={language} />
            <div className='text-center'>
                {parse(resources?.EventDetails ?? "")}                
                <div className="row pe-0 mb-5">
                    <div className="col-6 text-end">
                        <button className='button btn' onClick={() => navigate(-1)}>{resources?.Back}</button>
                    </div>
                    <div className="col-6 pe-0 text-start">
                        <button className='button btn' onClick={onSubmit}>{resources?.Next}</button>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Event