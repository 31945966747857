import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom/dist';
import axios from 'axios';
import { api, getEventById } from 'ticketino-api-client';
import parse from "html-react-parser";

import Header from './components/Header'
import Footer from './components/Footer'


const Login = () => {

    const [promotion, setPromotion] = useState("");
    const [ticketTypeId, setTicketTypeId] = useState(0);

    const [posId, setPosId] = useState(0);
    const [organizerId, setOrganizerId] = useState(0);

    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);
    const [isFormClosed, setIsFormClosed] = useState(false);

    const { eventId, language } = useParams();

    let languageId = 0;

    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("fr" || "FR"):
            languageId = 2;
            break;
        case ("en" || "EN"):
            languageId = 3;
            break;
        case ("it" || "IT"):
            languageId = 4;
            break;
        default:
            languageId = 0;
            break;
    }

    let navigate = useNavigate();

    let baseUrl = process.env.REACT_APP_BASEURL_API;
    api.defaults.baseURL = baseUrl;

    const [resources, setResources] = useState({});

    useEffect(() => {
        requestResources();
    }, [language]); //everytime language is changed

    useEffect(() => {

        const loadTokenAndEvent = async () => {
            await loadToken();
            validateEvent();
        };
        loadTokenAndEvent();
    }, []); //only on first page load

    const validateEvent = async () => {

        let theEvent = await getEventById(eventId);

        if (new Date(theEvent.preSaleEnd) < new Date(Date.now())) {
            console.log('theEvent');
            console.log(theEvent);
            setIsFormClosed(true);
        }
    }


    const loadToken = async () => {
        await axios.get("form/token").then((res) => {
            axios.defaults.headers.common["Authorization"] = "Bearer " + res.data;
            api.defaults.headers.common['Authorization'] = "Bearer " + res.data;
            sessionStorage.setItem("token", res.data);

            requestFormSettings();
        });
    };

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data?.translation);
                setLoading(false);
            })
            .catch((error) => console.error(error.response.data));
    };

    const requestFormSettings = async () => {
        await axios.get(`form/formsettings`).then((res) => {
            // setting the posID and eventID
            setPosId(res.data.posId);
            setOrganizerId(res.data.organizerId);

            if (eventId == res.data.eventId1) {
                setTicketTypeId(res.data.ticketTypeId1);
            }
        });
    };

    const applyPromotionCodeToOrder = async (orderId, code) => {
        try {
            const response = await axios.put(`${baseUrl}/ShopBasket/Order/${orderId}/PromotionCode/${code}`);
            return response.data?.unlockedTicketTypes;
        } catch (error) {
            console.error(error.response.data);
            setError(`${resources?.PromotionError1} "${code}" ${resources?.PromotionError2}`);
        }
    }

    const confirmShopbasketByOrderId = async (orderId) => {
        try {
            const res = await axios.put(`${baseUrl}/ShopBasket/Order/${orderId}/Confirm`);
            return res.data;
        } catch (error) {
            console.error(error);
            setError(error.response.data);
        }
    };

    const changePaymentTypeToFree = async (orderId) => {
        await axios
            .put(`${baseUrl}/ShopBasket/Order/${orderId}/PaymentType/5`)
            .then(async () => {
                await confirmShopbasketByOrderId(orderId);
            });
    }

    const getAddressOfPromotionCode = async (promotionCode, organizerId) => {
        try {
            const res = await axios.get(`${baseUrl}/PromotionCode/${promotionCode}/Address?organizerId=${organizerId}`);
            return res.data;
        } catch (error) {
            console.error(error);
        }
    }

    const addAddressToBasket = async (addressBody, orderId) => {
        await axios
            .put(`${baseUrl}/Order/${orderId}/Address`, addressBody)
            .catch((error) => {
                console.error(error.response.data);
            });
    };

    const startOrder = async (posId) => {
        let order = {
            affiliateShopId: null,
            currency: "CHF",
            tenantId: 1,
            languageId: languageId,
            pointOfSaleId: posId,
            abbreviation: "",
            paymentType: 1,
        };

        try {
            const response = await axios.post(`${baseUrl}/ShopBasket/Order`, order);
            return response.data;
        } catch (error) {
            setError(error.response.data)
            console.error(error.response.data);
        }
    };

    const addTicketToBasket = async (orderId) => {
        let addTicketTypes = [{
            ticketTypeId: ticketTypeId,
            quantity: 1,
            promotionCode: promotion
        }];

        await axios
            .post(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, {
                ticketsToAdd: addTicketTypes,
            })
            .then(() => {
                // saving the important values in the session
                sessionStorage.setItem("orderId", orderId);
                sessionStorage.setItem("eventId", eventId);
            })
            .catch((error) => {
                console.error(error.response.data);
                setError(error.response.data);
            });
    };

    const onSubmit = async () => {

        const order = await startOrder(posId);

        if (!isFormClosed) {
            if (order) {
                const updatedUnlockedTicketTypes = await applyPromotionCodeToOrder(order.id, promotion);

                if (updatedUnlockedTicketTypes?.includes(ticketTypeId)) {
                    await addTicketToBasket(order.id);
                    sessionStorage.setItem("appliedPromotion", promotion);

                    navigate(`/${language}/address`);
                }
            }
        } else {
            setError(resources?.LoginFormIsClosed)
        }
    }

    const onLogout = async () => {
        const order = await startOrder(posId);

        if (order) {
            const updatedUnlockedTicketTypes = await applyPromotionCodeToOrder(order.id, promotion);
            if (updatedUnlockedTicketTypes?.includes(ticketTypeId)) {
                await addTicketToBasket(order.id);

                const updatedAddress = await getAddressOfPromotionCode(promotion, organizerId);
                let addressBody = {
                    code4: "",
                    code5: "ABMELDUNG"
                }

                if (updatedAddress) {
                    addressBody.code4 = `${updatedAddress.firstName} ${updatedAddress.name}`;
                }

                await addAddressToBasket(addressBody, order.id);

                await changePaymentTypeToFree(order.id);
                navigate(`/${language}/logout`);
            }
        }
    }

    return (
        <div className='container page-container'>
            <Header language={language} />
            <div className='text-center'>
                <p className='fs-5 mb-5'>{parse(resources?.WelcomeText ?? "")}</p>
                <div className='mb-4'>
                    <div className="row">
                        <div className="col-md-6 col-12 mb-3">
                            <input className='form-control input' value={promotion} onChange={(e) => setPromotion(e.target.value)} />
                        </div>
                        <div className="col-md-6 col-12 row pe-0">
                            <div className="col-6">
                                <button className='button btn success mb-4 col-12' onClick={onSubmit}>{resources?.Login}</button>
                            </div>
                            <div className="col-6 pe-0">
                                <button className='button btn mb-4 col-12' onClick={onLogout}>{resources?.Logout}</button>
                            </div>
                        </div>
                        {error != "" && <div className="col-12">
                            <p className='fs-6' dangerouslySetInnerHTML={{ __html: error }}></p>
                        </div>}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Login