import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import './css/style.css';

import Home from "./views/Home";
import Login from "./views/Step10_Login";
import Address from "./views/Step20_Address";
import Confirmation from "./views/Step30_Confirmation";
import Logout from "./views/Step30_Logout";
import Event from "./views/Step05_Event";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/070924" replace />} />
        <Route path="/:eventId" element={<Home />} />
        <Route path="/:language/event" element={<Event />} />
        <Route path="/:language/login/:eventId" element={<Login />} />
        <Route path="/:language/address" element={<Address />} />
        <Route path="/:language/confirmation/:orderId" element={<Confirmation />} />
        <Route path="/:language/logout" element={<Logout />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
